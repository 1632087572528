<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					숙소 예약내역
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab">
						<a href="#">방문 전</a>
						<a href="#" class="active">방문 후</a>
						<a href="#">예약 취소</a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="centered centered--xlarge">
						<div class="centered__wrap">
							<div class="textbox">
								<div class="textbox__icon">
									<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
								</div>
								<h3 class="textbox__title">이용하신 숙소 내역이 없어요</h3>
								<p class="textbox__description">
									 다양한 숙소를 만나고 <br>
									지금 숙소를 예약해 보세요!
								</p>
								<div class="textbox__button">
									<button type="button" class="btn btn-primary purple">
										숙소 보러가기
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>